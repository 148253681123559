






























































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import MaterialCard from '@/components/MaterialCard.vue';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---
import crypto from 'crypto-js';

@Component({
  components: {
    MaterialCard,
  },
})
export default class LoginPage extends Vue {
  user = {
    username: '',
    password: '',
  };

  valid: boolean = false;
  clicked: boolean = false;
  loader: string = '';
  showPassword: boolean = false;
  rules = {
      required: (value: string) => !!value || '',
  };

  async login() {
    try {
      this.clicked = true;
      this.loader = 'clicked';

      const response = await AuthService.login({
        username: this.user.username, 
        password: crypto.SHA256(this.user.password).toString().toUpperCase(),
      });

      if (response && response.data) {
        if (response.data.result === 'false') {
          AppService.errorHandler(response.data.message);
        } else {
            this.$router.push('/');
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      if (error.response) {
        AppService.errorHandler(error.response.statusText);
      } else {
        AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
      }

    } finally {
      this.clicked = false;
      this.loader = '';
    }
  }

}

